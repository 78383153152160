import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchUploadEvidenceList(params) {
    return request({
        url: baseURL + '/uploadEvidence/list',
        method: 'GET',
        params: params
    })
}

export function fetchUploadEvidenceDetail(params) {
    return request({
        url: baseURL + '/uploadEvidence/detail',
        method: 'GET',
        params: params
    })
}

export function uploadEvidenceSave(data) {
    return request({
        url: baseURL + '/uploadEvidence/save',
        method: 'POST',
        data
    })
}